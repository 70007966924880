import moment from 'moment'
import { getCurrentUrl } from './index'
import 'moment/locale/fr'

let zendeskLanguageUpdateInterval = null

function populateZendeskFormWithUserDetails ({ email, firstname, lastname }) {
  if (typeof window.zE === 'undefined') {
    return
  }
  window.zE('webWidget', 'clear')
  if (!email) {
    return
  }
  let fullName = ''
  if (firstname && firstname.length) {
    fullName = firstname
  }
  if (lastname && lastname.length) {
    fullName = `${fullName} ${lastname}`
    fullName = fullName.trim()
  }
  if (!fullName.length) {
    // use the email in place of name
    fullName = email
  }
  window.zE('webWidget', 'prefill', {
    name: {
      value: fullName,
      readOnly: false // optional
    },
    email: {
      value: email,
      readOnly: false // optional
    }
  })
}

function setLanguage ({ language, addTranslationForLanguage, setActiveLanguage, getActiveLanguage, getUser, getPreviousLanguage }) {
  // Zendesk script file is loaded from the index.html.
  // We wait for the function to be available before changing Zendesk language.
  // Remove this code if Zendesk is removed from the app.

  const preventSettingZenDeskLanguage = window.location.href.includes('/widget/')

  if (!preventSettingZenDeskLanguage) {
    if (typeof window.zE === 'undefined') {
      let trialsCount = 0
      zendeskLanguageUpdateInterval = setInterval(() => {
        trialsCount++
        if (trialsCount === 10) console.error('Zendesk script seems to be unreachable. Did you remove the script from index.html and forget to remove this code ?')
        if (typeof window.zE !== 'undefined') {
          window.zE('webWidget', 'setLocale', language)
          window.zE('webWidget:on', 'open', () => { populateZendeskFormWithUserDetails(getUser()) })
          clearInterval(zendeskLanguageUpdateInterval)
        }
      }, 1000)
    } else {
      window.zE('webWidget', 'setLocale', language)
      window.zE('webWidget:on', 'open', () => { populateZendeskFormWithUserDetails(getUser()) })
    }
  }

  return new Promise((resolve) => {
    window.fetch(getCurrentUrl() + '/translations/' + language + '.json', {
      cache: 'no-store'
    })
      .then(function (response) {
        return response.json()
      })
      .then((translations) => {
        if (typeof getPreviousLanguage === 'function') {
          getPreviousLanguage(getActiveLanguage)
        }
        addTranslationForLanguage(translations, language)
        setActiveLanguage(language)
        // save the language to localstorage
        try {
          global.localStorage.setItem('interneoSelectedLanguage', language)
        } catch (err) {
          // nothing to do here
        }
        moment.locale(language)
        resolve()
      })
  })
}

export {
  setLanguage
}
