import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'

import AppLoading from './Components/shared/AppLoading'
import App from './App'
import ReactDOM from 'react-dom'

import AppClearCache from './Components/shared/AppClearCache'

import { BrowserRouter, Route } from 'react-router-dom'

import reducers from './reducers/index.js'
import { Provider as ReduxProvider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { initialize } from 'react-localize-redux'
import thunk from 'redux-thunk'
import { unregister } from './registerServiceWorker'
import { stopReportingRuntimeErrors } from "react-error-overlay";

// The next lines are needed to add a language in the app (plus the translations file of course).
// English moment locale is imported by default.
import 'moment/locale/fr'
import 'moment/locale/nl'
import { LANGUAGE_LOCALE_TAG_EN, LANGUAGE_LOCALE_TAG_FR_BE, LANGUAGE_LOCALE_TAG_FR_FR, LANGUAGE_LOCALE_TAG_NL, SUPPORTED_LANGUAGE_LOCALES } from './utils/constants.js'
// The default app language is the first in the array.
const languages = [
  { name: 'Français Belgique', code: LANGUAGE_LOCALE_TAG_FR_BE },
  { name: 'Français France', code: LANGUAGE_LOCALE_TAG_FR_FR },
  { name: 'English', code: LANGUAGE_LOCALE_TAG_EN },
  { name: 'Nederlands', code: LANGUAGE_LOCALE_TAG_NL }
]

const middleware = [thunk]
const createStoreWithMiddleware = applyMiddleware(
  ...middleware
)(createStore)

export const store = createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
)

// Disabling polyfill warning for "no-template-curly-in-string".
/* eslint-disable no-template-curly-in-string */
// const store = createStore(reducers);

// Check if the language was previously saved to localstorage
let previouslySelectedLanguage = LANGUAGE_LOCALE_TAG_FR_BE

try {
  previouslySelectedLanguage = global.localStorage.getItem('interneoSelectedLanguage')

  if (!SUPPORTED_LANGUAGE_LOCALES.includes(previouslySelectedLanguage)) {
    previouslySelectedLanguage = LANGUAGE_LOCALE_TAG_FR_BE
  }
} catch (err) {
  previouslySelectedLanguage = LANGUAGE_LOCALE_TAG_FR_BE
}

global.localStorage.setItem('interneoSelectedLanguage', previouslySelectedLanguage)

store.dispatch(initialize(languages, { missingTranslationMsg: '${key}', defaultLanguage: previouslySelectedLanguage }))

if (process.env.NODE_ENV === "development") {
  stopReportingRuntimeErrors(); // disables error overlays
}

const mainApp = (
  <div className='interneo-app notranslate' translate='no'>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Route path='/*'>
          <App languages={languages} />
        </Route>
      </BrowserRouter>
    </ReduxProvider>
  </div>
)

const app = process.env.NODE_ENV === 'production' ? (
  <AppClearCache>
    {({ loading, isLatestVersion, emptyCacheStorage }) => {
      if (loading) {
        return <AppLoading />
      } else if (!isLatestVersion) {
        setTimeout(() => {
          emptyCacheStorage()
        }, 100)
        return <AppLoading />
      }
      return mainApp
    }}
  </AppClearCache>
) : mainApp

unregister()
ReactDOM.render(app, document.getElementById('root'))
