const INTERNEO_FAQ_URL_PART_1 = 'https://support.opalsolutions.be/hc/'
const INTERNEO_FAQ_URL_PART_2 = '/categories/360003066859-Comment-utiliser-Interneo-'
export const LANGUAGE_TAG_EN = 'en'
export const LANGUAGE_TAG_FR = 'fr'
export const LANGUAGE_TAG_NL = 'nl'
export const LANGUAGE_LOCALE_TAG_FR_BE = 'fr_be'
export const LANGUAGE_LOCALE_TAG_FR_FR = 'fr_fr'
export const LANGUAGE_LOCALE_TAG_EN = 'en'
export const LANGUAGE_LOCALE_TAG_NL = 'nl'

// GLOBAL CONFIGURATION PART
export const SUPPORTED_LANGUAGES = [LANGUAGE_TAG_EN, LANGUAGE_TAG_FR, LANGUAGE_TAG_NL]
export const SUPPORTED_LANGUAGE_LOCALES = [LANGUAGE_LOCALE_TAG_EN, LANGUAGE_LOCALE_TAG_FR_BE, LANGUAGE_LOCALE_TAG_FR_FR, LANGUAGE_LOCALE_TAG_NL]
export const SUCCESS_API_REQUEST_STATE = 'success'

const FULL_DATE_WITHOUT_TIMEZONE = 'YYYY-MM-DD HH:mm:ss'
const DATE_WITHOUT_TIME = 'YYYY-MM-DD'
const EUROPEAN_DATE = 'DD/MM/YYYY'
const SHORT_EUROPEAN_DATE = 'DD/MM'

export const ANTD_FORM_VALIDATE_STATUS = 'success'
export const ANTD_FORM_ERROR_STATUS = 'error'

export const SUCCESS_REQUEST_STATUS = 'success'
export const NULL_SECTION = 'global'

export const DATE_FORMAT_API = 'YYYY-MM-DD'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const URI_FORMAT = 'YYYY-MM-DD'
export const internshipStatesStringToNum = filterState => internshipStates[filterState.replace('INTERNSHIP_STATE_', '')]
export const internshipOrderStringToShort = sortBy => sortBy.replace('SORT_BY_', '')

/** Contexts **/

export const ADMIN_CONTEXT = 'ADMIN'
export const SCHOOL_CONTEXT = 'SCHOOL'
export const INSTITUTION_CONTEXT = 'HOSPITAL'
export const NURSES_CONTEXT = [SCHOOL_CONTEXT, INSTITUTION_CONTEXT]
export const SCHOOL_MACCS_CONTEXT = 'SCHOOL_MACCS'
export const INSTITUTION_MACCS_CONTEXT = 'INSTITUTION_MACCS'
export const MACCS_CONTEXT = [SCHOOL_MACCS_CONTEXT, INSTITUTION_MACCS_CONTEXT]
export const SCHOOL_SIDE_CONTEXTS = [SCHOOL_CONTEXT, SCHOOL_MACCS_CONTEXT]
export const INSTITUTION_SIDE_CONTEXTS = [INSTITUTION_CONTEXT, INSTITUTION_MACCS_CONTEXT]
export const STUDENT_CONTEXT = 'STUDENT'
export const ASSISTANT_CONTEXT = 'ASSISTANT'
export const NONE_CONTEXT = 'NONE'

/** Part of the constant related to user roles */
export const STANDARD_DRAWER_WIDTH = '550px'

/** ********************* DIMENSIONS PART ********************************************/

export const XS = 768 // px very small => android
export const SM = 992 // px small device => tablet
export const MD = 1200 // px

/** ********************* END OF DIMENSIONS PART ********************************************/

/** ******************** USER ROLES PART ***********************/
// super admin role
export const ROLE_ADMIN = 'ROLE_ADMIN'

/** NURSES User roles */
// roles for users in the hospital side
export const ROLE_INSTITUTION_GROUP_ADMIN = 'ROLE_INSTITUTION_GROUP_ADMIN'
export const ROLE_HOSPITAL_ADMIN = 'ROLE_HOSPITAL_ADMIN'
export const ROLE_NURSE = 'ROLE_NURSE'
export const ROLE_VALIDATOR = 'ROLE_VALIDATOR'
export const INSTITUTION_ROLES = [
  ROLE_INSTITUTION_GROUP_ADMIN,
  ROLE_HOSPITAL_ADMIN,
  ROLE_NURSE,
  ROLE_VALIDATOR
]

// roles for users in the school side
export const ROLE_ENCODER = 'ROLE_ENCODER'
export const ROLE_COORDINATOR = 'ROLE_COORDINATOR'
export const ROLE_SUPERVISOR = 'ROLE_SUPERVISOR'
export const ROLE_UNKNOW = 'ROLE_UNKNOW'

export const SCHOOL_ROLES = [
  ROLE_ENCODER,
  ROLE_COORDINATOR,
  ROLE_SUPERVISOR
]

/** MACCS user roles */
export const ROLE_ADMIN_RESEAU = 'ROLE_ADMIN_RESEAU'
export const ROLE_PRESI_MASTER = 'ROLE_PRESI_MASTER'
export const ROLE_CLINICAL_SECRETARY = 'ROLE_CLINICAL_SECRETARY'
export const SCHOOL_MACCS_ROLES = [
  ROLE_ADMIN_RESEAU,
  ROLE_CLINICAL_SECRETARY,
  ROLE_PRESI_MASTER
]

export const ROLE_LOCAL_SECRETARY = 'ROLE_LOCAL_SECRETARY'
export const ROLE_ADMIN_HOSPITAL_RO = 'ROLE_ADMIN_HOSPITAL_RO'
export const ROLE_INTERNSHIP_MASTER_MACCS = 'ROLE_INTERNSHIP_MASTER_MACCS'
export const INSTITUTION_MACCS_ROLES = [
  ROLE_LOCAL_SECRETARY,
  ROLE_ADMIN_HOSPITAL_RO,
  ROLE_INTERNSHIP_MASTER_MACCS
]

export const ROLE_ASSISTANT = 'ROLE_ASSISTANT'
export const ROLE_STUDENT = 'ROLE_STUDENT'

export const SUPPORTED_ROLES = [
  ROLE_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN, ROLE_HOSPITAL_ADMIN, ROLE_NURSE, ROLE_ENCODER, ROLE_COORDINATOR, ROLE_SUPERVISOR, ROLE_VALIDATOR,
  ROLE_STUDENT, ROLE_LOCAL_SECRETARY, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_CLINICAL_SECRETARY, ROLE_ADMIN_RESEAU, ROLE_ASSISTANT, ROLE_PRESI_MASTER
]

/** ****************** END OF USER ROLES PART ******************/

export const CAN_VALIDATE_SCHEDULE = [
  ROLE_HOSPITAL_ADMIN, ROLE_NURSE, ROLE_VALIDATOR, ROLE_ENCODER, ROLE_COORDINATOR, ROLE_SUPERVISOR,
  ROLE_ADMIN_RESEAU, ROLE_PRESI_MASTER, ROLE_CLINICAL_SECRETARY, ROLE_INTERNSHIP_MASTER_MACCS, ROLE_LOCAL_SECRETARY
]
// INSTITUTION OPTIONS PART
export const INSTITUTION_OPTIONS = {
  DISPLAY_QUOTA_PER_SECTOR: 'display_quotas_per_sector',
  MODULE_SETTINGS: 'module_settings',
  DISPLAY_INTERNSHIPS_WITHOUT_STUDENT: 'display_internships_without_student'
}
export const INSTITUTION_OPTION_PUBLIPOSTAGE = 'publipostage'

// ORDER BY PART
export const ORDER_BY = {
  ACT_TYPE: 'act_type',
  ADDRESS: 'address',
  BIRTHDATE: 'birthdate',
  CATEGORY: 'category',
  CITY: 'city',
  COMMENT: 'comment',
  COUNTRY: 'country',
  CREATED_AT: 'created-at',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  EMPLACEMENT: 'emplacement',
  END_DATE: 'end-date',
  FIRSTNAME: 'firstname',
  FIRST_NAME: 'first-name',
  INSTITUTION: 'institution',
  LANGUAGE: 'language',
  LASTNAME: 'lastname',
  LAST_NAME: 'last-name',
  LAST_CONNECTION: 'last-login',
  NAME: 'name',
  ORIGINAL_NAME: 'original-name',
  PHONE_NUMBER: 'phone-number',
  ZIPCODE: 'zipcode',
  REGISTRATION: 'registration-number',
  REQUEST_ORIGIN: 'request-origin',
  ROLE: 'role',
  SCHOOL: 'school',
  SCHOOL_YEAR: 'school-year',
  SCHOOL_YEAR_NAME: 'school-year-name',
  SECTION: 'section',
  SECTOR: 'sector',
  STATE: 'state',
  START_DATE: 'start-date',
  TYPE: 'type',
  USERNAME: 'username',
  COORDINATOR: 'coordinator'
}

export const ORDER_BY_CREATED_AT = 'created-at'
export const ORDER_BY_INSTITUTION = 'institution'
export const ORDER_BY_SCHOOL = 'school'
export const ORDER_BY_START_DATE = 'start-date'
export const ORDER_BY_SECTION = 'section'
export const ORDER_BY_SECTOR = 'sector'
export const ORDER_BY_STUDENT = 'student'
export const ORDER_BY_ASSISTANT = 'assistant'
export const ORDER_BY_SCHOOL_SPECIALITY = 'school-speciality'
export const SEARCH_BADGE = 'search-badge'

export const DIRECTION_ASC = 'asc'
export const DIRECTION_DESC = 'desc'

export const MODES = {
  INTERNEO: 'INTERNEO',
  WIDGET: 'WIDGET'
}

const LIGHT_TEXT_COLOR = 'white'
const DARK_TEXT_COLOR = 'black'

// institution options available values
const RECRUITMENT_MODULE = 'recruitment_module'
const NURSE_PRESET_MANAGEMENT = 'nurse_preset_management'
const QUOTAS_PER_SECTOR = 'display_quotas_per_sector'
const RECRUITEMENT_GREEN_MATCH_EMAILING = 'recruitment_green_match_emailing'
const PUBLIPOSTAGE = 'publipostage'
const QUOTA_PER_PAUSE = 'quota_per_pause'
const AUTO_ACCEPTATION_DAYS = 'auto-acceptation-days'
export const DRAFT_INTERNSHIPS = 'draft_internships'
export const MODULE_SETTINGS = 'module_settings'
export const INTERNSHIPS_AUTO_ACCEPTATION = 'internships_auto_acceptation'
export const SCHEDULE_EDITION_BY_SCHOOLS = 'schedule_edition_by_schools'
export const STUDENT_DESIDERATA_LIMIT = 'student_desiderata_limit'
export const NURSE_INTERNSHIP_ACCCEPTANCE = 'nurse_internship_acceptance'
export const BLOCK_SCHEDULE_CODE_EDITION = 'block_schedule_code_edition'

/* List of all schools options */
export const SUPERVISORS_EDIT_SCHEDULE = 'supervisors_edit_schedule'
export const CUSTOM_LOGO = 'custom_logo'
export const ACT_HOURS_VERIFICATION = 'act_hours_verification'
export const INTERNSHIPS_CARPOOLING = 'internships_carpooling'
export const DRAFT_INTERNSHIPS_TYPE = 'draft_internships'
export const TIVOLI_CUSTOM_EXPORT = 'tivoli_custom_export'

export const GENDER_OPTIONS = translate => {
  return ([
    { id: 'M', name: translate('M') },
    { id: 'F', name: translate('F') },
    { id: 'Other', name: translate('Other') }
  ])
}

export const COUNTRIES = translate => {
  return ([
    { id: 'BE', name: translate('Belgium') },
    { id: 'FR', name: translate('France') },
    { id: 'LU', name: translate('Luxembourg') },
    { id: 'NL', name: translate('Netherlands') },
    { id: 'CH', name: translate('Switzerland') },
    { id: 'Other', name: translate('Other') }
  ])
}

const INSTITUTION_CONSTRAINT_TYPES = {
  INTERNSHIPS_BLOCKING: 'INTERNSHIPS_BLOCKING'
}
const ENTITY_TYPES = {
  STUDENT: 'Student'
}

/** ****************** START OF INTERNSHIP PART ******************/
export const PENDING_STUDENT_DATA_MISSING = -2
export const PENDING_NO_SECTOR = -1
export const CREATED = 0
export const PENDING = 1
export const ACCEPTED = 3
export const AUTO_ACCEPTED = 4
export const REFUSED = 5
export const CANCELED = 6
export const ACCEPTED_UNMANAGED = 7
export const SCHEDULE_ADDED = 8
export const SCHEDULE_VALIDATED = 9
export const SCHEDULE_TO_VALIDATE = 10

export const internshipStates = {
  PRE_ACCEPTED: -3,
  PENDING_STUDENT_DATA_MISSING: -2,
  PENDING_NO_SECTOR: -1,
  CREATED: 0,
  PENDING: 1,
  ACCEPTED: 3,
  AUTO_ACCEPTED: 4,
  REFUSED: 5,
  CANCELED: 6,
  ACCEPTED_UNMANAGED: 7,
  SCHEDULE_ADDED: 8,
  SCHEDULE_VALIDATED: 9,
  SCHEDULE_TO_VALIDATE: 10
}

export const internshipStatesString = {
  '-3': 'PRE_ACCEPTED',
  '-2': 'PENDING_STUDENT_DATA_MISSING',
  '-1': 'PENDING_NO_SECTOR',
  0: 'CREATED',
  1: 'PENDING',
  3: 'ACCEPTED',
  4: 'AUTO_ACCEPTED',
  5: 'REFUSED',
  6: 'CANCELED',
  7: 'ACCEPTED_UNMANAGED',
  8: 'SCHEDULE_ADDED',
  9: 'SCHEDULE_VALIDATED',
  10: 'SCHEDULE_TO_VALIDATE'
}

export const INTERNSHIP_STATES_STRING = {
  PRE_ACCEPTED: 'pre-accepted',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  PENDING: 'pending',
  REFUSED: 'refused',
  SCHEDULE_ADDED: 'schedule-added',
  TO_VALIDATE: 'to-validate',
  VALIDATED: 'validated',
  WITHOUT_INSTITUTION: 'without-institution'
}

export const ACCEPTED_BY_INSTITUTION_STATES = [
  ACCEPTED, REFUSED, SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE
]

/** ****************** START OF INTERNSHIP PART ******************/

// SUPPORTED LANGUAGES
export const FR = 'fr'
export const EN = 'en'
export const NL = 'nl'

export const OPTIN_TYPES = {
  RECRUITEMENT_DATA_USAGE: 'RECRUITEMENT_DATA_USAGE',
  GENERAL_CONDITIONS: 'GENERAL_CONDITIONS'
}

export const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

export const MACCS_ENVIRONMENT = 'maccs'
export const NURSE_ENVIRONMENT = 'nurse'
export const SUPPORTED_ENVIRONMENTS = [MACCS_ENVIRONMENT, NURSE_ENVIRONMENT]

export {
  FULL_DATE_WITHOUT_TIMEZONE,
  DATE_WITHOUT_TIME,
  EUROPEAN_DATE,
  SHORT_EUROPEAN_DATE,
  INTERNEO_FAQ_URL_PART_1,
  INTERNEO_FAQ_URL_PART_2,
  LIGHT_TEXT_COLOR,
  DARK_TEXT_COLOR,
  RECRUITMENT_MODULE,
  NURSE_PRESET_MANAGEMENT,
  QUOTAS_PER_SECTOR,
  RECRUITEMENT_GREEN_MATCH_EMAILING,
  PUBLIPOSTAGE,
  QUOTA_PER_PAUSE,
  AUTO_ACCEPTATION_DAYS,
  INSTITUTION_CONSTRAINT_TYPES,
  ENTITY_TYPES
}
