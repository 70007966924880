import React, { useContext, useEffect, useState } from 'react'

import { Alert, Button, Input, Select, Tooltip } from 'antd'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faEdit, faExclamationTriangle, faGlobeEurope, faUser } from '@fortawesome/free-solid-svg-icons'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'

import '../../../assets/user-form.scss'

const FIRSTNAME = 'firstname'
const LASTNAME = 'lastname'
const EMAIL = 'email'
const USERNAME = 'username'
const LANGUAGE = 'language'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const UserForm = ({ data, user, t, onSave }) => {
  const { languages } = useContext(GlobalContext)

  const [displayWarning, setDisplayWarning] = useState({ email: false, username: false })
  const [localUser, setLocalUser] = useState({})
  const [error, setError] = useState({ message: '', visible: false, style: { borderColor: 'red' }, missingFields: [] })

  useEffect(() => {
    setLocalUser({ ...data })
  }, [data])

  useEffect(() => {
    setDisplayWarning({
      email: user.email !== localUser.email,
      username: user.username !== localUser.username
    })
  }, [user.email, user.username, localUser.email, localUser.username])

  const handleSubmit = () => {
    let visible = false
    let message = ''
    const buffer = []
    const requiredFields = [FIRSTNAME, LASTNAME, EMAIL, USERNAME, LANGUAGE]

    requiredFields.forEach(field => {
      if (!localUser[field]) {
        message = t('Please fill in all fields')
        buffer.push(field)
        visible = true
      }
    })

    setError({
      ...error,
      missingFields: buffer,
      visible: visible,
      message: message
    })

    if (buffer.length === 0) {
      onSave(localUser)
    }
  }

  return (
    <div className='user-form'>
      {error.visible && (
        <div className='form-alert'>
          <Alert style={{ width: '100%' }} message={error.message} type='error' showIcon />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className='form-item'>
          <label> {t('Firstname')} </label>
          <Input
            style={error.missingFields.includes(FIRSTNAME) ? error.style : { borderColor: 'lightgray' }}
            value={localUser.firstname}
            onChange={e => setLocalUser({ ...localUser, firstname: e.target.value })}
          />
        </div>
        <div style={{ width: '1.5vh' }} />
        <div className='form-item'>
          <label> {t('Lastname')} </label>
          <Input
            style={error.missingFields.includes(LASTNAME) ? error.style : { borderColor: 'lightgray' }}
            value={localUser.lastname}
            onChange={e => setLocalUser({ ...localUser, lastname: e.target.value })}
          />
        </div>
      </div>
      <div className='form-item'>
        <label>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div> <FontAwesomeIcon icon={faAt} /> {t('Email')} </div>
            {displayWarning.email && (
              <Tooltip title={t('user_form.auto_logout_warning')}>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ff9800' }} />
              </Tooltip>
            )}
          </div>
        </label>
        <Input
          style={error.missingFields.includes(EMAIL) ? error.style : { borderColor: displayWarning.email ? '#ff9800' : 'lightgray' }}
          value={localUser.email}
          onChange={e => setLocalUser({ ...localUser, email: e.target.value })}
        />
      </div>
      <div className='form-item'>
        <label>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div><FontAwesomeIcon icon={faUser} /> {t('Username')}</div>
            {displayWarning.username && (
              <Tooltip title={t('user_form.auto_logout_warning')}>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ff9800' }} />
              </Tooltip>
            )}
          </div>
        </label>
        <Input
          style={error.missingFields.includes(USERNAME) ? error.style : { borderColor: displayWarning.username ? '#ff9800' : 'lightgray' }}
          value={localUser.username}
          onChange={e => setLocalUser({ ...localUser, username: e.target.value })}
        />
      </div>
      <div className='form-item form-select'>
        <label><FontAwesomeIcon icon={faGlobeEurope} /> {t('Language')}</label>
        <Select
          style={error.missingFields.includes(LANGUAGE) ? error.style : { borderColor: 'lightgray' }}
          value={localUser.language}
          onChange={value => setLocalUser({ ...localUser, language: value })}
        >
          {languages.map(l => {
            return (
              <Select.Option key={l.languageTag} value={l.languageTag}>
                {t(l.name)}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <div className='form-footer'>
        <Button
          type='primary'
          disabled={false}
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faEdit} size='1x' />&nbsp;
          {t('Save changes')}
        </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(UserForm)
