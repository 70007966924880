import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, Checkbox } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
const FormItem = Form.Item

class NormalLoginForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err && values.username.length > 0 && values.password.length > 0) {
        this.props.onLogin(values)
      }
    })
  }

  handleForgotPassword = (e) => {
    e.preventDefault()
    this.props.onPasswordResetClick()
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <Form onSubmit={this.handleSubmit} className='login-form'>
        <div className='labels'>
          <div className='title'>{this.props.t('Internships management')}</div>

          <div style={{ textAlign: 'center' }}>
            {this.props.t('nurses')}&nbsp;
            <span>-</span>&nbsp;{this.props.t('doctors')}&nbsp;
            <span>-</span>&nbsp;{this.props.t('paramedics')}&nbsp;
            <span>-</span>&nbsp;{this.props.t('midwifes')}
          </div>
        </div>
        <FormItem style={{ marginBottom: '10px' }}>
          <label>{this.props.t('Email address')}</label>
          {getFieldDecorator('username', { rules: [] })(
            <Input prefix={<FontAwesomeIcon icon='user' />} placeholder={this.props.t('Email address')} />
          )}
        </FormItem>
        <FormItem style={{ marginBottom: '10px' }}>
          <label>{this.props.t('Password')}</label>
          {getFieldDecorator('password', { rules: [] })(
            <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={this.props.t('Password')} />
          )}
        </FormItem>
        <a href='#/' onClick={this.handleForgotPassword} className='forgot-password-link'>{this.props.t('Forgotten password ?')}</a><br />
        <br />
        <a className='forgot-password-link' href='https://opalsolutions.zendesk.com/hc/fr/categories/360003066859-Comment-utiliser-Interneo-' target="_blank">F.A.Q.</a>
        {this.props.t('and')}&nbsp;
        <a className='forgot-password-link' href='https://www.youtube.com/channel/UCdF4BK3XhULkd3809NHp5pw/featured' target="_blank">{this.props.t('video')}</a>

        <div className='sign-in-wrapper'>
          <FormItem>
            {getFieldDecorator('remember', { valuePropName: 'checked', initialValue: true })(
              <Checkbox><span className='remember-text'>{this.props.t('Remember me')}</span></Checkbox>
            )}
          </FormItem>
        </div>
        <div className='sign-in-wrapper' style={{ position: 'relative' }}>
          <Button type='primary' htmlType='submit' className='login-button' loading={this.props.loginPending}>
            {this.props.t('Log in')}
          </Button>

          <img src='/assets/opal-logo.png' className='opal-logo-form' />
        </div>

      </Form>
    )
  }
}

const LoginForm = Form.create()(NormalLoginForm)
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
