import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import AlertFreezeModal from '../Components/shared/AlertFreezeModal'
import AppBanner from '../Components/shared/AppBanner'
import AppTour from '../Components/shared/AppTour'
import Help from '../Components/shared/Help'
import MenuDesktop from '../Components/shared/MenuDesktop'
import MenuMobile from '../Components/shared/MenuMobile'
import UserSettings from '../Components/shared/UserSettings'
import { Button, Menu, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import UnsupportedBrowser from '../Components/shared/UnsupportedBrowser'
import { getAdminMenu, getSchoolMenu, getInstitutionMenu, getSchoolMaccsMenu, getInstitutionMaccsMenu, getStudentMenu, getAssistantMenu } from '../utils/menus'
import { mapStateToProps, mapDispatchToProps, connect } from '../reducers/Dispatchers'
import routes from '../routes'
import {
  ADMIN_CONTEXT, ASSISTANT_CONTEXT, INSTITUTION_CONTEXT, INSTITUTION_MACCS_CONTEXT, SCHOOL_CONTEXT, SCHOOL_MACCS_CONTEXT, STUDENT_CONTEXT,
  CUSTOM_LOGO, ROLE_STUDENT, ROLE_ASSISTANT, MACCS_CONTEXT, ROLE_INSTITUTION_GROUP_ADMIN, MACCS_ENVIRONMENT, NURSE_ENVIRONMENT, ROLE_VALIDATOR
} from '../utils/constants'
import { URIDecode } from '../utils/apiHelper'
import InternshipsToBeEvaluatedModal from '../HOC/InternshipsToBeEvaluatedModal'
import EvaluationsProvider from '../Providers/EvaluationsProvider'
import NotificationBell from '../Components/shared/NotificationBell'
import LanguagesMenu from '../Components/shared/LanguagesMenu'
import SchoolRouter from '../Routers/SchoolRouter'
import InstitutionRouter from '../Routers/InstitutionRouter'
import StudentRouter from '../Routers/StudentRouter'
import AdminRouter from '../Routers/AdminRouter'
import InstitutionGroupAdminRouter from '../Routers/InstitutionGroupAdminRouter'
import AssistantRouter from '../Routers/Maccs/AssistantRouter'
import MaccsInstitutionGroupAdminRouter from '../Routers/Maccs/MaccsInstitutionGroupAdminRouter'
import MaccsInstitutionRouter from '../Routers/Maccs/MaccsInstitutionRouter'
import MaccsSchoolRouter from '../Routers/Maccs/MaccsSchoolRouter'
import ValidatorRouter from '../Routers/ValidatorRouter'

class AppManager extends Component {
  state = {
    desktopMenuCollapsed: true,
    mobileMenuCollapsed: true,
    menuItems: [],
    moduleOption: null
  };

  /**
   * contentWrapperRef -> this is the ref of the content div. It's used to determine how much the div was scrolled
   */
  contentWrapperRef = React.createRef();

  componentDidMount () {
    this.updateMenuData(null, null, this.props.environment)
  }

  componentDidUpdate (previousProps) {
    const { environment, getActiveLanguage, getUser, getInstitutionOptionTypes, getInstitutionOptions } = this.props

    if (
      previousProps.getActiveLanguage !== getActiveLanguage ||
      previousProps.getUser.id !== getUser.id ||
      getInstitutionOptionTypes.optionTypes !== previousProps.getInstitutionOptionTypes.optionTypes ||
      getInstitutionOptions !== previousProps.getInstitutionOptions ||
      environment !== previousProps.environment
    ) {
      this.updateMenuData(getInstitutionOptionTypes.optionTypes, getInstitutionOptions, environment)
    }
  }

updateMenuData = (institutionOptionsType, institutionOptions, environment) => {
  const menuItems = this.getMenu(institutionOptionsType, institutionOptions, environment)

  // @TODO 2023-05-08: remove this IF when the changelog is working well on MACCS side
  if (
    !MACCS_CONTEXT.includes(this.props.getUser.context) &&
    (this.props.environment !== MACCS_ENVIRONMENT ||
    !this.props.getUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN))
  ) {
    menuItems.push({
      iconName: faInfoCircle,
      label: this.props.t('Changelog'),
      route: routes.CHANGELOG
    })
  }

  this.setState({
    menuItems: menuItems.map((mi, index) => {
      mi.id = index
      mi.label = mi.label + (mi.admin ? ' [' + this.props.t('ADMIN') + ']' : '')

      return mi
    })
  })
};

updateMenuData = (institutionOptionsType, institutionOptions, environment) => {
  const user = this.props.getUser
  const { context, roles, institutions } = user
  const t = this.props.t
  let menuItems = []

  if (context === ADMIN_CONTEXT) {
    menuItems = getAdminMenu(t)
  } else if (context === SCHOOL_CONTEXT) {
    menuItems = getSchoolMenu(t, roles)
  } else if (context === INSTITUTION_CONTEXT) {
    menuItems = getInstitutionMenu(t, roles, institutions[0], institutionOptionsType, institutionOptions, (state) => this.setState(state), environment)
  } else if (context === STUDENT_CONTEXT) {
    menuItems = getStudentMenu(t)
  } else if (context === SCHOOL_MACCS_CONTEXT) {
    menuItems = getSchoolMaccsMenu(t, roles)
  } else if (context === INSTITUTION_MACCS_CONTEXT) {
    menuItems = getInstitutionMaccsMenu(t, roles)
  } else if (context === ASSISTANT_CONTEXT) {
    menuItems = getAssistantMenu(t)
  }

  if (!MACCS_CONTEXT.includes(context) && (environment !== MACCS_ENVIRONMENT || !roles.includes(ROLE_INSTITUTION_GROUP_ADMIN))) {
    menuItems.push({
      iconName: faInfoCircle,
      label: this.props.t('Changelog'),
      route: routes.CHANGELOG
    })
  }

  this.setState({ menuItems })
};

  handleToggleDesktopMenuExpand = () => {
    this.setState({ desktopMenuCollapsed: !this.state.desktopMenuCollapsed })
    // Some components in the app need the window resize event for their rendering (SmartTable, InternshipsManager, ...).
    // The menu transition is set to 500ms in the CSS, we trigger the resize event after 550ms to be sure it is finished.

    setTimeout(function () {
      var event = window.document.createEvent('UIEvents')
      event.initUIEvent('resize', true, false, window, 0)
      window.dispatchEvent(event)
    }, 550)
  };

  handleToggleMobileMenuExpand = () => {
    this.setState({
      mobileMenuCollapsed: !this.state.mobileMenuCollapsed
    })
  };

  setCurrentDate = (date, callback) => {
    this.setState(
      {
        currentDate: date
      },
      () => {
        this.props.history.push(routes.SHIFTS_MANAGER)
        if (typeof callback === 'function') {
          callback()
        }
      }
    )
  };

  displayManagePresets = sector => {
    this.setState(
      {
        selectedSector: sector || null
      },
      () => {
        this.props.history.push(routes.PRESETS_MANAGER)
      }
    )
  };

  /**
   * This is called in 100 ms after a scroll happens on the main content div
   */
  handleOnScrollDebounced = debounce(() => {
    const {
      getAppScroll: { hScroll },
      setAppHorizontalScroll,
      location
    } = this.props
    if (
      location.pathname === routes.SHIFTS_MANAGER &&
      this.contentWrapperRef.current
    ) {
      const { scrollLeft } = this.contentWrapperRef.current
      if (hScroll !== scrollLeft) {
        setAppHorizontalScroll(scrollLeft)
      }
    }
  }, 100);

  renderContent = () => {
    const {
      getUser: {
        context,
        roles,
        switchUser,
        coordinatedSections,
        managedSectors,
        institutions,
        school
      },
      getAppScroll: { width }
    } = this.props

    const userSwitchBarWidth =
      width && width > window.innerWidth ? width + 20 : 'auto' // I've added 20 which is 2 * 10px paddings

    if (this.state.menuItems.length === 0) return null

    let accessRestricted = false
    let switchUserInfo = null
    if (typeof switchUser !== 'undefined') {
      switchUserInfo = (
        <div
          className={`inner-spacing userswitch-info flex-row ${context === 'STUDENT' ? 'sticky' : ''}`}
          style={{ width: userSwitchBarWidth }}
        >
          <FontAwesomeIcon icon='user-secret' />
          &nbsp;{this.props.t('Connected as')}:&nbsp;
          {URIDecode(switchUser)}
          {roles.map(r => {
            return <span key={r}>&nbsp;[{r}]</span>
          })}
          {institutions && institutions.map(i => {
            return <span key={i}>&nbsp; - {i.name}</span>
          })}
          {school && <span>&nbsp; {school.name}</span>}
        </div>
      )
    }

    let alertFreezeModal = null
    if (roles.indexOf('ROLE_COORDINATOR') > -1) {
      let userSections = coordinatedSections
      if (this.props.getSchools.length > 0) {
        userSections = userSections.filter(
          us => us.id !== this.props.getSchools[0].rootSection.id
        )
      }
      if (userSections.length === 0) {
        alertFreezeModal = (
          <AlertFreezeModal
            text={
              <span>
                {this.props.t(
                  'You connected as a sections coordinator, but your account does not coordinate any section.'
                )}
                &nbsp;
                {this.props.t(
                  'Please contact your Interneo administrator in order to fix that.'
                )}
              </span>
            }
            actionButton={
              <Button type='primary' onClick={this.props.onLogout}>
                <FontAwesomeIcon icon='sign-out-alt' />
                &nbsp;{this.props.t('Log out')}
              </Button>
            }
          />
        )
        accessRestricted = true
      }
    }

    let Router = null

    if (context === SCHOOL_CONTEXT) {
      Router = SchoolRouter
    } else if (roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
      Router = this.props.environment === NURSE_ENVIRONMENT ? InstitutionGroupAdminRouter : MaccsInstitutionGroupAdminRouter
    } else if (roles.includes(ROLE_VALIDATOR)) {
      Router = ValidatorRouter
    } else if (context === INSTITUTION_CONTEXT) {
      Router = InstitutionRouter
    } else if (context === STUDENT_CONTEXT) {
      Router = StudentRouter
    } else if (context === ADMIN_CONTEXT) {
      Router = AdminRouter
    } else if (context === ASSISTANT_CONTEXT) {
      Router = AssistantRouter
    } else if (context === SCHOOL_MACCS_CONTEXT) {
      Router = MaccsSchoolRouter
    } else if (context === INSTITUTION_MACCS_CONTEXT) {
      Router = MaccsInstitutionRouter
    }

    if (roles.indexOf('ROLE_NURSE') > -1 && managedSectors.length === 0) {
      alertFreezeModal = (
        <AlertFreezeModal
          text={
            <span>
              {this.props.t(
                'You connected as an care units supervisor, but your account does not supervise any care unit.'
              )}
              &nbsp;
              {this.props.t(
                'Please contact your Interneo administrator in order to fix that.'
              )}
            </span>
          }
          actionButton={
            <Button type='primary' onClick={this.props.onLogout}>
              <FontAwesomeIcon icon='sign-out-alt' />
              &nbsp;{this.props.t('Log out')}
            </Button>
          }
        />
      )
      accessRestricted = true
    }

    return (
      <div
        className='flex-column flex-fill'
        style={{ overflow: 'auto' }}
        onScroll={this.handleOnScrollDebounced}
        ref={this.contentWrapperRef}
      >
        <AppBanner />
        {switchUserInfo}
        {alertFreezeModal}
        <div className={context === STUDENT_CONTEXT ? '' : 'inner-spacing'} style={{ paddingBottom: '0px' }}>
          {!accessRestricted && (
            <InternshipsToBeEvaluatedModal>
              <Router moduleOption={this.state.moduleOption} onLogout={this.props.onLogout} />
            </InternshipsToBeEvaluatedModal>
          )}
        </div>
      </div>
    )
  };

  renderTopBarMenu = () => {
    return (
      <Menu
        style={{ minWidth: '300px' }}
      >
        {this.props.getUser.roles.includes(ROLE_ASSISTANT) && (
          <Menu.Item>
            <UserSettings />
          </Menu.Item>
        )}
        {!this.props.getUser.roles.includes(ROLE_ASSISTANT) && (
          <div>
            <UserSettings />
          </div>
        )}
        <Menu.Divider />
        <Menu.Item onClick={() => { this.props.history.push(routes.PROFILE_PAGE) }}>
          <FontAwesomeIcon icon='id-badge' />
          &nbsp;{this.props.t('My profile')}
        </Menu.Item>
        <Menu.Item onClick={this.props.onLogout}>
          <FontAwesomeIcon icon='sign-out-alt' />
          &nbsp;{this.props.t('Log out')}
        </Menu.Item>
        <Menu.Divider />
        {this.props.languagesMenu}
      </Menu>
    )
  };

  render () {
    const {
      location: { pathname }
    } = this.props

    const menuItems = this.state.menuItems
    menuItems.map(menuItem => {
      menuItem.showLabel = !this.state.desktopMenuCollapsed
      return menuItem
    })

    let currentPage = this.state.menuItems.filter(
      mi => mi.route === pathname
    )[0]
    if (!currentPage && pathname === routes.SHIFTS_MANAGER) {
      // set the page name
      currentPage = { label: this.props.t('Shifts Manager') }
    }

    const allDataReady =
      Object.keys(this.props.getDataReady).filter(
        d => !this.props.getDataReady[d]
      ).length === 0
    const loadingIcon = allDataReady || this.props.getUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) ? null : (
      <Tooltip placement='top' title={this.props.t('Data are loading.')}>
        <div className='loading-icon' />
      </Tooltip>
    )

    const customLogoOption = this.props.schoolOptions.find(so => so.optionType.type === CUSTOM_LOGO && so.optionEnabled)
    let customLogo = null

    if (customLogoOption) {
      customLogo = customLogoOption.optionValue
    }

    const primaryUserInfos = {
      firstname: this.props.getUser.firstname,
      lastname: this.props.getUser.lastname,
      username: this.props.getUser.username
    }

    return (
      <div className='flex-row' style={{ width: '100vw', height: '100vh' }}>
        <MenuDesktop
          collapsed={this.state.desktopMenuCollapsed}
          menuItems={this.state.menuItems}
        />
        <EvaluationsProvider>
          <div className='flex-column flex-fill' style={{ overflow: 'auto' }}>
            <div
              className='inner-spacing flex-row top-bar'
              style={customLogo ? { height: '70px', alignItems: 'center' } : { height: '52px' }}
            >
              <Tooltip
                placement='top'
                title={
                  this.state.desktopMenuCollapsed
                    ? this.props.t('Expand menu')
                    : this.props.t('Collapse menu')
                }
              >
                <Button
                  className='hidden-mobile'
                  type='primary'
                  onClick={this.handleToggleDesktopMenuExpand}
                >
                  <FontAwesomeIcon icon='bars' />
                </Button>
              </Tooltip>
              <Button
                className='hidden-desktop'
                type='primary'
                onClick={this.handleToggleMobileMenuExpand}
              >
                <FontAwesomeIcon
                  icon={this.state.mobileMenuCollapsed ? 'bars' : 'times'}
                />
              </Button>
              <div className='h-spacing' />
              <div style={{ fontSize: '18px', marginTop: '2px' }}>
                {typeof currentPage === 'undefined' ? '' : currentPage.label}
              </div>
              <div className='h-spacing' />
              {customLogo && (
                <img src={'/assets/' + customLogo} style={{ marginRight: '10px' }} />
              )}
              {loadingIcon}
              <div className='flex-fill' />
              {this.props.getUser.roles.includes(ROLE_STUDENT) && (
                <NotificationBell />
              )}
              <div className='h-spacing' />
              <div className='flex-row'>
                <div style={{ fontSize: '18px', marginTop: '2px' }}>
                  {typeof currentPage === 'undefined' ? ''
                    : <div>{primaryUserInfos.firstname} {primaryUserInfos.lastname} ({primaryUserInfos.username})</div>}
                </div>
                <div className='h-spacing' />
                <Help page={currentPage} />
                <div className='h-spacing' />
                <LanguagesMenu
                  icon={faCog}
                  title={this.props.t('Settings')}
                  type='primary'
                  onLogout={this.props.onLogout}
                />
              </div>
            </div>
            <MenuMobile
              collapsed={this.state.mobileMenuCollapsed}
              menuItems={this.state.menuItems}
            />
            <AppTour />
            <UnsupportedBrowser />
            {this.renderContent()}
          </div>
        </EvaluationsProvider>
      </div>
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppManager)
