import { Col, DatePicker, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { callFunctionIfDefined } from '../../../utils/functionHelper'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { EN, FR, NL } from '../../../utils/constants'
import moment from 'moment'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    activeLanguage: getActiveLanguage(state.locale).code
  }
}

const InternshipDataModal = ({ activeLanguage, internship, maskClosable, studentKey, onCancel, onOk, t }) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (internship) {
      setStartDate(moment(internship.startDate.split('+')[0]))
      setEndDate(moment(internship.endDate.split('+')[0]))
    }
  }, [internship])

  const handleCancel = () => {
    callFunctionIfDefined(onCancel, [])
  }

  const handleOk = () => {
    callFunctionIfDefined(onOk, [internship, { startDate, endDate }])
  }

  const renderTitle = () => {
    if (internship === null) {
      return ''
    }

    if (activeLanguage === EN) {
      return `${internship[studentKey]?.firstname ?? t(`Unkown ${studentKey}`)}${t("'s internship")}`
    }

    if (activeLanguage === FR || activeLanguage === NL) {
      return `${t("'s internship")} ${internship[studentKey]?.firstname ?? t(`unkown ${studentKey}`)}`
    }

    return 'UNKNOW LANGUAGE'
  }

  return (
    <Modal
      className='internship-data-modal'
      title={renderTitle()}
      visible={internship !== null}
      onCancel={handleCancel}
      onOk={handleOk}
      maskClosable={maskClosable ?? false}
    >
      <Row>
        <Col span={6}>
          {`${t('Start date')} :`}
        </Col>
        <Col span={18}>
          <DatePicker value={startDate} onChange={setStartDate} />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {`${t('End date')} :`}
        </Col>
        <Col span={18}>
          <DatePicker value={endDate} onChange={setEndDate} />
        </Col>
      </Row>
    </Modal>
  )
}

export default connect(mapStateToProps)(InternshipDataModal)
