import React, { useEffect, useRef, useState, useContext } from 'react'

import { connect, useDispatch } from 'react-redux'
import { getAppStates } from '../../../reducers/AppStates/actions'
import { DATE_WITHOUT_TIME, DIRECTION_DESC, internshipStates, ORDER_BY_CREATED_AT } from '../../../utils/constants'
import { getUser } from '../../../reducers/UserReducer'
import { purgeMetadata } from '../../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'
import { getState } from '../../../reducers/MaccsInternshipsManager/actions'
import { deleteMaccsInternship, getAllMaccsInternships, updateMaccsInternship } from '../../../utils/api/maccsInternship'
import MaccsInternshipsDataProvider from '../../../Context/InternshipsManager/MaccsInternshipsDataProvider'
import MaccsInternshipsTable from '../../../Components/MACCS/InternshipsManager/MaccsInternshipsTable'
import MaccsInternshipsHeader from '../../../Components/MACCS/InternshipsManager/MaccsInternshipsHeader'
import EarlyDateModal from '../../../Components/shared/Modals/EarlyDateModal'
import { MACCS_INTERNSHIPS_MANAGER } from '../../../reducers/MaccsInternshipsManager/actionsType'
import AssistantInfo from '../../../Components/MACCS/Internships/AssistantInfo'
import MaccsInternshipInfo from '../../../Components/shared/MaccsInternshipInfo'
import InternshipRejectionModal from '../../../Components/MACCS/InternshipsManager/InternshipRejectionModal/InternshipRejectionModal'
import MaccsInternshipValidation from '../../../Components/shared/ShiftsManager/MaccsInternshipValidation/MaccsInternshipValidation'
import InternshipDataModal from '../../../Components/MACCS/InternshipsManager/InternshipDataModal'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import { getTotalsMessageUnreadByMaccsInternshipsIds } from '../../../utils/api/maccsInternshipMessage'
import { notification } from 'antd'
import { selectAppLoading } from '../../../reducers/AppStates/selectors'
import { getFirstMondayOfMonth, getLastSundayOfMonth } from '../../../utils/momentjs'

import './assets/maccs-internships-manager.scss'

const DEFAULT_NUMBER_OF_MONTHS = 11
const DEFAULT_META_DATA = {
  page: 1,
  last: 0,
  perPage: 10,
  totalPages: 1,
  total: 0
}
const TIME_BEFORE_SEARCH = 200
const INTERNSHIPS_NUMBER = 10
const TIME_RELOAD_MESSAGES = 30000

const mapStateToProps = state => {
  return {
    currentDate: getAppStates(state).date,
    refusedInternship: getState(state).refusedInternship,
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    appLoading: selectAppLoading(state)
  }
}

const MaccsInternshipsManagerView = props => {
  const { internshipToEdit, setInternshipToEdit } = useContext(InternshipsManagerContext)

  const [addMode, setAddMode] = useState(false)
  const [internships, setInternships] = useState([])
  const [totalsMessageUnreadByInternships, setTotalsMessageUnreadByInternships] = useState({})
  const [metaData, setMetaData] = useState(DEFAULT_META_DATA)
  const [state, setState] = useState(null)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState(ORDER_BY_CREATED_AT)
  const [sortDirection, setSortDirection] = useState(DIRECTION_DESC)
  const [page, setPage] = useState(1)
  const [colShownCount, setColShowCount] = useState(DEFAULT_NUMBER_OF_MONTHS)
  const [selectedAssistant, setSelectedAssistant] = useState(null)
  const [selectedMaccsInternship, setSelectedMaccsInternship] = useState(null)
  const [internshipToValidate, setInternshipToValidate] = useState(null)
  const [displayEarlyModal, setDisplayEarlyModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const fetchTimer = useRef()

  useEffect(() => {
    if (props.user) {
      setLoading(true)

      const parameters = buildUrlParemeters({
        startDate: getFirstMondayOfMonth(props.currentDate),
        months: colShownCount,
        search,
        state,
        orderBy,
        sortDirection,
        page,
        perPage: INTERNSHIPS_NUMBER
      })

      postponeInternshipsFetch(parameters, props.user)
    }
  }, [
    props.user, page, search, state,
    orderBy, sortDirection, colShownCount, props.currentDate
  ])

  useEffect(() => {
    if (internships.length > 0) {
      runTotalsMessage(internships, props.user)

      const interval = setInterval(function () {
        runTotalsMessage(internships, props.user)
      }, TIME_RELOAD_MESSAGES)

      return () => clearInterval(interval)
    }
  }, [internships])

  const runTotalsMessage = (internships, user) => {
    const ids = []

    internships.map(i => ids.push(i.id))

    getTotalsMessageUnreadByMaccsInternshipsIds(ids.join('&ids[]='), user).then(response => {
      setTotalsMessageUnreadByInternships(response.data)
    })
  }

  const postponeInternshipsFetch = (urlParameters, user) => {
    if (typeof fetchTimer.current === 'number') {
      clearTimeout(fetchTimer.current)
    }

    fetchTimer.current = setTimeout(() => {
      fetchInternships(urlParameters, user)
    }, TIME_BEFORE_SEARCH)
  }

  const handleInternshipDeletion = internship => {
    setLoading(true)
    deleteMaccsInternship(internship, props.user)
      .then(json => {
        if (json.data) {
          refreshInternships()
          notification.success({
            message: props.t('The internship was successfully deleted'),
            placement: 'bottomRight'
          })
        } else {
          setLoading(false)
        }
      })
  }

  const refreshInternships = () => {
    const parameters = buildUrlParemeters({
      startDate: getFirstMondayOfMonth(props.currentDate),
      months: colShownCount,
      search,
      state,
      orderBy,
      sortDirection,
      page,
      perPage: INTERNSHIPS_NUMBER
    })

    fetchInternships(parameters, props.user)
  }

  const buildUrlParemeters = parameters => {
    const endDate = getLastSundayOfMonth(parameters.startDate.clone().add(parameters.months, 'month'))

    return {
      'start-date': parameters.startDate.format(DATE_WITHOUT_TIME),
      'end-date': endDate.format(DATE_WITHOUT_TIME),
      search: parameters.search,
      state: parameters.state,
      'order-by': parameters.orderBy,
      'order-direction': parameters.sortDirection,
      page: parameters.page,
      'per-page': parameters.perPage
    }
  }

  const fetchInternships = (urlParameters, user) => {
    getAllMaccsInternships(urlParameters, user)
      .then(json => {
        if (json) {
          if (json.data) {
            setInternships(json.data)
          }

          if (json.meta) {
            const meta = purgeMetadata(json.meta.pages)

            setMetaData(meta)
          }
        }

        setLoading(false)
      })
  }

  const handleInternshipRefused = () => {
    refreshInternships()
  }

  const handleInternshipValidation = () => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        return { ...internship, state: internshipStates.SCHEDULE_VALIDATED }
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: internshipStates.SCHEDULE_VALIDATED })
  }

  const handleInternshipInvalidation = newState => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        return { ...internship, state: newState }
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: newState })
  }

  const handleRejectionClosing = () => {
    dispatch({
      type: MACCS_INTERNSHIPS_MANAGER.SET_REFUSED_MACCS_INTERNSHIP,
      payload: null
    })

    refreshInternships()
  }

  const toggleAddMode = () => {
    setAddMode(!addMode)
  }

  const onInternshipUpdate = (internship, body) => {
    updateMaccsInternship(internship, body, props.user)
      .then(json => {
        if (json && json.data) {
          setInternshipToEdit(null)
          refreshInternships()
        }
      })
  }

  return (
    <div className='maccs-internships-manager'>
      <MaccsInternshipsHeader
        addMode={addMode}
        state={state}
        search={search}
        orderBy={orderBy}
        sortDirection={sortDirection}
        page={page}
        metaData={metaData}
        loadShiftsManager={props.loadShiftsManager}
        onStateChange={setState}
        onPageChange={setPage}
        onSearchChange={setSearch}
        onMonthsChange={setColShowCount}
        onOrderByChange={setOrderBy}
        onSortDirectionChange={setSortDirection}
        toggleAddMode={toggleAddMode}
      />
      <MaccsInternshipsDataProvider
        addMode={addMode}
        internships={internships}
        totalsMessageUnreadByInternships={totalsMessageUnreadByInternships}
        onEarlyStartDate={setDisplayEarlyModal}
        onInternshipDelete={handleInternshipDeletion}
        onInternshipSelected={setSelectedMaccsInternship}
        onStudentSelected={setSelectedAssistant}
        refreshInternships={refreshInternships}
        setInternshipToValidate={setInternshipToValidate}
      >
        <MaccsInternshipsTable loading={loading || props.appLoading} />
      </MaccsInternshipsDataProvider>
      <AssistantInfo
        assistant={selectedAssistant}
        onClose={() => setSelectedAssistant(null)}
      />
      <MaccsInternshipInfo
        maccsInternship={selectedMaccsInternship}
        onClose={() => setSelectedMaccsInternship(null)}
      />
      {internshipToValidate && (
        <MaccsInternshipValidation
          maccsInternship={internshipToValidate}
          onClose={() => setInternshipToValidate(null)}
          onValidate={handleInternshipValidation}
          onInvalidate={handleInternshipInvalidation}
          bottom
        />
      )}
      <InternshipRejectionModal
        internship={props.refusedInternship}
        onRejection={handleInternshipRefused}
        onClose={handleRejectionClosing}
      />
      <InternshipDataModal
        internship={internshipToEdit}
        studentKey='assistant'
        onCancel={() => setInternshipToEdit(null)}
        onOk={onInternshipUpdate}
      />
      {displayEarlyModal && (
        <EarlyDateModal
          t={props.t}
          onVisibleChange={setDisplayEarlyModal}
          visible
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(MaccsInternshipsManagerView)
